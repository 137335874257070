<div class="p-2 w-full h-full">
<h1 class="text-accent">Disc Search</h1>

<mat-dialog-content class="w-full h-full" >

    <div class="w-full flex gap-2 justify-center items-center">
        <mat-form-field class="w-4/5 mb-2" subscriptSizing="dynamic">
            <mat-label>Disc Name</mat-label>
            <input matInput [(ngModel)]="discName" (keyup.enter)="searchForDisc()">
        </mat-form-field>
        <button mat-mini-fab color="accent" (click)="searchForDisc()"><mat-icon color="primary">search</mat-icon></button>
    </div>

    @if (!searched){ <p class="text-center">Search for a disc to add to your collection!</p>}
    @if(searched && results.length > 0){
        <ul>
            @for(disc of results; track disc.id){
                <li class="flex items-center gap-2 mt-2">
                    <span><b>{{disc.name}}</b>&nbsp;|&nbsp;{{disc.brand}}</span>
                    <button mat-icon-button color="accent" (click)="addDiscToCollection(disc)"><mat-icon>add</mat-icon></button>
                </li>
            }
        </ul>
        
    } @else if (searched && !results.length) {
        <p>No results found. Try again...</p>
    }
    
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="collection" cdkFocusInitial color="warn">Close</button>
  </mat-dialog-actions>
</div>