<div class="bg-mat-dark w-full h-full flex flex-wrap lg:flex-nowrap overflow-scroll">
  <app-bag
    [collection]="collection()"
    class=" lg:h-full w-full lg:w-1/3"
  ></app-bag>
  <section class="w-full lg:w-2/3  lg:h-full p-4 relative">
    <h1 class="text-accent">Collection</h1>
    <button
      mat-mini-fab
      color="accent"
      (click)="openDiscSearchModal()"
      id="add-btn"
    >
      <mat-icon color="primary">add</mat-icon>
    </button>

    @for(disc of collection(); track disc.id){ @if(!disc.bag){
    <li class="flex items-center text-accent gap-2 mt-2">
      <span class="text-white">
        <strong>{{ disc.name }}</strong> - <em>{{ disc.brand }}</em>
        <br/> {{disc.speed}} | {{disc.glide}} | {{disc.turn}} | {{disc.fade}} </span
      >
      <button
        class="invisible"
        mat-icon-button
        color="white"
        (click)="addDiscToBag(disc)"
      >
        <mat-icon>backpack</mat-icon>
      </button>
      <button
        class="invisible"
        mat-icon-button
        color="white"
        (click)="deleteDisc(disc.id)"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <mat-icon [matMenuTriggerFor]="menu" class="invisible" color="accent"
        >flight</mat-icon
      >
      <mat-menu #menu="matMenu">
        <img [src]="disc.flightpath" alt="flightpath" />
      </mat-menu>
    </li>
    } }
  </section>
</div>
