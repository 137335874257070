<div class="h-full bg-gray-dark p-4">
  <h1 class="text-accent">Bag</h1>
  @for(disc of collection; track disc.id){ @if(disc.bag){
  <li class="flex items-center text-accent gap-2 mt-2">
    <span class="text-white">
      <strong>{{ disc.name }}</strong> - <em>{{ disc.brand }}</em>
      <br/> {{disc.speed}} | {{disc.glide}} | {{disc.turn}} | {{disc.fade}} </span
    >
    <button
      mat-icon-button
      color="white"
      (click)="addDiscToCollection(disc)"
      class="invisible"
    >
      <mat-icon>warehouse</mat-icon>
    </button>
    <mat-icon [matMenuTriggerFor]="menu" class="invisible" color="accent"
      >flight</mat-icon
    >
    <mat-menu #menu="matMenu">
      <img [src]="disc.flightpath" alt="flightpath" />
    </mat-menu>
  </li>
  } }
</div>
